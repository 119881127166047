import BottomBar from "./BottomBar";
import TopBar from "./TopBar";

const ProjectsPage = () => {
    return(  
        <div className="flex justify-center items-center w-screen h-screen text-xl text-secondary MainBG">
            <h1>WIP projectspage</h1>
        </div>
    );
}

export default ProjectsPage;