import React, { Component } from 'react';
import './App.css';
import StreamEmbed from './components/Embed';

import Home from './components/Home';


function App() {
  return (
    <div className="body">    
      <Home/>
    </div>
    
  );
}

export default App;