const BottomBar = () => {
    return (
        <div className="fixed bottom-0 left-0 h-8 w-screen flex flex-row backdrop-brightness-50 text-secondary justify-center items-center">
            <div className="fixed flex justify-center">
                <p className="text-sm">&copy; LibreBun 2024</p>
            </div>
        </div>
    );
};

export default BottomBar;