import { useEffect, useState } from 'react';

function Home() {
  const [Versions, setVersions] = useState('');

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const Response = await fetch('versions.txt');
        const Text = await Response.text();
        setVersions(Text);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    fetchFiles();
  }, []);

  return (
    <div className='flex flex-col justify-center items-center w-screen h-screen overflow-hidden MainBG'>
      <div className='flex flex-col items-center'>
        <p className='text-4xl mb-2 text-secondary'>Welcome to Zoey's Bits!</p>
        <p className='text-xl mb-8 text-secondary'>The home of all my bits and pieces.</p>
      </div>
      <div className='flex'>
        <div className='flex flex-col w-fit h-fit text-secondary rounded-md items-center'>
          <p className='text-xl'>Feel free to use my public services.</p>
          <div className='grid grid-cols-3 grid-rows-2 gap-4 mx-2 my-2'>
            <a href='https://git.li-yo.ts.net/' target='_blank' rel='noopener noreferrer' className='backdrop-brightness-75 shadow-md rounded-md p-1 flex LogoBoxesPub'>
              <img className='Logos' draggable='false' src={process.env.PUBLIC_URL + '/forgejologo.svg'} alt='Memos' />
              <p className='self-center mx-2 PubP'>Forgejo</p>
            </a>
            <a href='https://lemmy.li-yo.ts.net/' target='_blank' rel='noopener noreferrer' className='backdrop-brightness-75 shadow-md rounded-md p-1 flex LogoBoxesPub'>
              <img className='Logos' draggable='false' src={process.env.PUBLIC_URL + '/lemmylogo.svg'} alt='Lemmy' />
              <p className='self-center mx-2 PubP'>Lemmy</p>
            </a>
            <a href='https://clips.li-yo.ts.net/' target='_blank' rel='noopener noreferrer' className='backdrop-brightness-75 shadow-md rounded-md p-1 flex LogoBoxesPub'>
              <img className='Logos' draggable='false' src={process.env.PUBLIC_URL + '/firesharelogo.png'} alt='Fireshare' />
              <p className='self-center mx-2 PubP'>Fireshare</p>
            </a>
            <a href='https://memos.li-yo.ts.net/' target='_blank' rel='noopener noreferrer' className='backdrop-brightness-75 shadow-md rounded-md p-1 flex LogoBoxesPub'>
              <img className='Logos' draggable='false' src={process.env.PUBLIC_URL + '/memoslogo.png'} alt='Memos' />
              <p className='self-center mx-2 PubP'>Memos</p>
            </a>
            <a href='https://send.li-yo.ts.net/' target='_blank' rel='noopener noreferrer' className='backdrop-brightness-75 shadow-md rounded-md p-1 flex LogoBoxesPub'>
              <img className='Logos' draggable='false' src={process.env.PUBLIC_URL + '/sendlogo.svg'} alt='Send' />
              <p className='self-center mx-2 PubP'>Send</p>
            </a>
            <a href='https://cast.li-yo.ts.net/' target='_blank' rel='noopener noreferrer' className='backdrop-brightness-75 shadow-md rounded-md p-1 flex LogoBoxesPub'>
              <img className='Logos' draggable='false' src={process.env.PUBLIC_URL + '/owncastlogo.png'} alt='Owncast' />
              <p className='self-center mx-2 PubP'>Owncast</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default Home;