import BottomBar from "./BottomBar";
import TopBar from "./TopBar";

const StreamPage = () => {
    return(  
        <div className="flex flex-col items-center justify-center w-screen h-screen text-xl text-secondary MainBG">
            <div className="flex flex-row items-center justify-center w-screen h-5/6 mt-8 text-xl text-secondary">    
                <iframe
                    className="mt-5 overflow-hidden justify-left flex"
                    src="https://cast.li-yo.ts.net/embed/video"
                    title="Owncast"
                    height="100%" width="60%"
                    referrerpolicy="origin"
                    allowfullscreen>
                </iframe>
                <iframe
                    className="ml-5 mt-5 overflow-scroll"
                    src="https://cast.li-yo.ts.net/embed/chat/readwrite/"
                    height="100%" width="30%">
                </iframe>
            </div>
            <a href="https://cast.li-yo.ts.net/" className="my-5">Join me on my actual Owncast site!</a>
        </div>
    );
}

export default StreamPage;