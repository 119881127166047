import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import AboutPage from './components/About';
import StreamPage from './components/Stream';
import ProjectsPage from './components/Projects';
import BlogPage from './components/Blog';
import TopBar from './components/TopBar';
import BottomBar from './components/BottomBar';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
    <> 
    <TopBar/>
    <App/>
    <BottomBar/>
    </>
    ),
  },
  {
    path: "/about",
    element: (
    <> 
    <AboutPage/>
    <TopBar/>
    <BottomBar/>
    </>
    ),
  },
  {
    path: "/stream",
    element: (
    <> 
    <StreamPage/>
    <TopBar/>
    <BottomBar/>
    </>
    ),
  },
  {
    path: "/projects",
    element: (
    <> 
    <ProjectsPage/>
    <TopBar/>
    <BottomBar/>
    </>
    ),
  },
  {
    path: "/blog",
    element: (
    <> 
    <BlogPage/>
    <TopBar/>
    <BottomBar/>
    </>
    ),
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
  <RouterProvider router={router} />
  </StrictMode>
);

reportWebVitals();
