import React from "react";
import { useNavigate } from "react-router-dom";

const TopBar = () => {
    const navigate = useNavigate();
    return (
        <div className="fixed top-0 left-0 h-16 w-screen flex flex-row backdrop-brightness-75 text-secondary items-center">
        <div className="topbar-clickables">
                <div className="flex flex-row">
                    <div className="scale-50 BunnyLogo"> 
                        <div className="">
                            <img onClick={() => navigate("/")} draggable="false" src={process.env.PUBLIC_URL + "/bunnie96px.svg"} alt="Bunnie"/>
                        </div>
                    </div>
                    <div className="flex space-x-3">
                        <button className="HeaderButt" onClick={() => navigate("/blog")}>Blog</button>
                        <button className="HeaderButt" onClick={() => navigate("/projects")}>Projects</button>
                        <button className="HeaderButt" onClick={() => navigate("/stream")}>Stream</button>
                        <button className="HeaderButt" onClick={() => navigate("/about")}>About</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;